var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b60e60975ae7c02477c87be4881cd45f9a79f97c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

import { APP_VERSION, SENTRY_DSN } from './src/common/constants'

Sentry.init({
  dsn: SENTRY_DSN,
  release: APP_VERSION,
  tracesSampleRate: 0,
  enabled: process.env.NODE_ENV !== 'development',
  environment:
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'unknown-environment',
})
